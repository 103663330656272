@tailwind base;
@tailwind components;
@tailwind utilities;

@import url(http://fonts.googleapis.com/css?family=Raleway);

:root {
    --colour-dark: #1a1a1a;
    --colour-provaz: #850d23;
}

html {
    box-sizing: border-box;
    font-size: calc(1em + 0.9vw);
    height: 100%;
}

@media only screen and (min-width: 800px) {
    html {
        font-size: 16px;
    }
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

body {
    /*line-height: 1 rem;*/
    background-color: var(--colour-dark);
    color: var(--colour-provaz);
    font-family: "Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
    font-weight: 300;
    height: 100vh;
    margin: 0;
    padding: 0;
}

/* generic ********************************************************************/
/*
#background-fx {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
}
*/
